exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-discover-rokid-ar-glasses-ai-devices-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=K:/Development/MetaPixelX/electornic-blog/src/content/blog/discover-rokid-ar-glasses-ai-devices/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-discover-rokid-ar-glasses-ai-devices-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-supernova-bluetooth-speaker-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=K:/Development/MetaPixelX/electornic-blog/src/content/blog/supernova-bluetooth-speaker/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-supernova-bluetooth-speaker-index-mdx" */),
  "component---src-templates-post-list-template-tsx-content-file-path-src-content-blog-supernova-bluetooth-speaker-index-mdx": () => import("./../../../src/templates/post-list-template.tsx?__contentFilePath=K:/Development/MetaPixelX/electornic-blog/src/content/blog/supernova-bluetooth-speaker/index.mdx" /* webpackChunkName: "component---src-templates-post-list-template-tsx-content-file-path-src-content-blog-supernova-bluetooth-speaker-index-mdx" */),
  "component---src-templates-tag-list-tsx-content-file-path-tag-ar": () => import("./../../../src/templates/tag-list.tsx?__contentFilePath=/tag/ar" /* webpackChunkName: "component---src-templates-tag-list-tsx-content-file-path-tag-ar" */),
  "component---src-templates-tag-list-tsx-content-file-path-tag-glass": () => import("./../../../src/templates/tag-list.tsx?__contentFilePath=/tag/glass" /* webpackChunkName: "component---src-templates-tag-list-tsx-content-file-path-tag-glass" */),
  "component---src-templates-tag-list-tsx-content-file-path-tag-post": () => import("./../../../src/templates/tag-list.tsx?__contentFilePath=/tag/post" /* webpackChunkName: "component---src-templates-tag-list-tsx-content-file-path-tag-post" */),
  "component---src-templates-tag-list-tsx-content-file-path-tag-test": () => import("./../../../src/templates/tag-list.tsx?__contentFilePath=/tag/test" /* webpackChunkName: "component---src-templates-tag-list-tsx-content-file-path-tag-test" */)
}

